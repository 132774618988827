import React from 'react';
import { Link } from 'gatsby';
import { nameCapitalized } from '../../../components/Script/script';

const UnitsInfo = ({ unitOne, unitTwo, pathOfUnitParent, unitOf, infoNode }) => {
  return (
    <>
      <div>
        <Link to={`/${pathOfUnitParent}/`}>
          <h2>{nameCapitalized(unitOf)} Units</h2>
        </Link>
        <p>{infoNode}</p>
        <br />
        {unitOne.notes ? <h3>{nameCapitalized(unitOne.name)}</h3> : ''}
        {unitOne.notes ? (
          <p
            dangerouslySetInnerHTML={{
              __html: unitOne.notes,
            }}
          />
        ) : (
          ''
        )}
        <br />
        {unitTwo.notes ? <h3>{nameCapitalized(unitTwo.name)}</h3> : ''}
        {unitTwo.notes ? (
          <p
            dangerouslySetInnerHTML={{
              __html: unitTwo.notes,
            }}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default React.memo(UnitsInfo);

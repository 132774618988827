import React from "react"
import { nameCapitalized } from "../../../components/Script/script"

const HowManyInAUnit = ({ unitOne, unitTwo, selectOne, selectTwo }) => {
  const result = ((1 * selectOne) / selectTwo).toFixed(6)
  const result2 = ((1 * selectTwo) / selectOne).toFixed(6)
  return (
    <div>
      <h4>
        {nameCapitalized(unitTwo.name)} to {nameCapitalized(unitOne.name)}{" "}
        Formula
      </h4>

      {result < result2 ? (
        <p>
          Multiply the {unitTwo.name} value by {result2}
          <br />
          {unitOne.name} = {unitTwo.name} x {result2}
        </p>
      ) : (
        <p>
          Divide the {unitTwo.name} value by {result}
          <br />
          {unitOne.name} = {unitTwo.name} / {result}
        </p>
      )}

      <h4>
        {nameCapitalized(unitOne.name)} to {nameCapitalized(unitTwo.name)}{" "}
        Formula
      </h4>

      {result < result2 ? (
        <p>
          Divide the {unitOne.name} value by {result2}
          <br />
          {unitTwo.name} = {unitOne.name} / {result2}
        </p>
      ) : (
        <p>
          Multiply the {unitOne.name} value by {result}
          <br />
          {unitTwo.name} = {unitOne.name} x {result}
        </p>
      )}
      <br />
      <h4>
        How many {unitTwo.name} in a {unitOne.name}?
      </h4>
      <p>
        One {unitOne.name} {unitOne.symbol ? `(${unitOne.symbol})` : ""} is
        equal to {result} {unitTwo.name}{" "}
        {unitTwo.symbol ? `(${unitTwo.symbol})` : ""}
        <br />1 {unitOne.name} {unitOne.symbol ? `(${unitOne.symbol})` : ""} ={" "}
        {result} {unitTwo.name} {unitTwo.symbol ? `(${unitTwo.symbol})` : ""}
      </p>
      <br />
      <h4>
        How many {unitOne.name} in a {unitTwo.name}?
      </h4>
      <p>
        One {unitTwo.name} {unitTwo.symbol ? `(${unitTwo.symbol})` : ""} is
        equal to {result2} {unitOne.name}{" "}
        {unitOne.symbol ? `(${unitOne.symbol})` : ""}
        <br />1 {unitTwo.name} {unitTwo.symbol ? `(${unitTwo.symbol})` : ""} ={" "}
        {result2} {unitOne.name} {unitOne.symbol ? `(${unitOne.symbol})` : ""}
      </p>
      <br />
    </div>
  )
}

export default React.memo(HowManyInAUnit)

import React, { useState, useMemo } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import NavBar from "../../components/NavBar/NavBar"
import { nameCapitalized, isSSR } from "../../components/Script/script"

import HowManyInAUnit from "./component/howManyInA"
import UnitsInfo from "./component/everyUnitInfo"

import {
  ConverterWrapper,
  ConverterSideA,
  ConverterSideB,
  ConverterEquals,
} from "./component/unitStyle"

import { Columns, Column, Title } from "../../StyleComponents/styles"

const ListUnitsLink = React.lazy(() => import("./component/unitListLinks"))

const IndexPage = props => {
  const { pageContext } = props
  let [valueOne, setvalueOne] = useState("")
  let [valuetwo, setvaluetwo] = useState("")
  let unitOne = pageContext.singlePageInfo.one
  let unitTwo = pageContext.singlePageInfo.two
  let selectOne = unitOne.multiplier
  let selectTwo = unitTwo.multiplier

  function inputOne(e) {
    e.preventDefault()
    if (e.target.value === "") {
      setvalueOne(e.target.value)
      setvaluetwo("")
    } else {
      setvalueOne(e.target.value)
      let result = parseFloat(e.target.value) * selectOne
      result = result / selectTwo
      setvaluetwo(result.toFixed(6))
    }
  }
  function inputtwo(e) {
    e.preventDefault()
    if (e.target.value === "") {
      setvaluetwo(e.target.value)
      setvalueOne("")
    } else {
      setvaluetwo(e.target.value)
      let result = parseFloat(e.target.value) * selectTwo
      result = result / selectOne
      setvalueOne(result.toFixed(6))
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`${nameCapitalized(unitOne.name)} to ${nameCapitalized(
          unitTwo.name
        )} Converter`}
        description={`Convert ${unitOne.name} to ${unitTwo.name},  How many ${unitTwo.name} in ${unitOne.name} ?
      How many ${unitOne.name} in ${unitTwo.name} ?`}
        keywords={[
          unitOne.name,
          unitTwo.name,
          `${unitOne.name} to ${unitTwo.name}`,
          `${unitTwo.name} to ${unitOne.name}`,
          `How many ${unitTwo.name} in ${unitOne.name}`,
          `How many ${unitOne.name} in ${unitTwo.name}`,
          `convert ${unitOne.name} to ${unitTwo.name}`,
          `convert ${unitTwo.name} to ${unitOne.name}`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Converter", link: "/units-converter/" },
            {
              name: `${pageContext.unitOf} Converter`,
              link: `/${pageContext.pathOfUnitParent}/`,
            },
            {
              name: `${unitOne.name} to ${unitTwo.name}`,
              link: `/${pageContext.pageLink}/`,
            },
          ]
        }, [
          pageContext.unitOf,
          pageContext.pathOfUnitParent,
          unitOne.name,
          unitTwo.name,
          pageContext.pageLink,
        ])}
      />
      <div className="container">
        <ConverterWrapper>
          <Title>
            {nameCapitalized(unitOne.name)} to {nameCapitalized(unitTwo.name)}
          </Title>
          <Columns>
            <Column>
              <ConverterSideA>
                <form
                  name="form_A"
                  onSubmit={e => {
                    e.preventDefault()
                  }}
                >
                  <input
                    type="number"
                    id="A"
                    name="A"
                    maxLength="20"
                    value={valueOne}
                    onChange={inputOne}
                  />
                  <label htmlFor="A" className="withoutArrow">
                    <p>
                      {unitOne.name}{" "}
                      {unitOne.symbol ? `(${unitOne.symbol})` : ""}
                    </p>
                  </label>
                </form>
              </ConverterSideA>
            </Column>
            <ConverterEquals>
              <span>&#61;</span>
            </ConverterEquals>
            <Column>
              <ConverterSideB>
                <form
                  name="form_B"
                  onSubmit={e => {
                    e.preventDefault()
                  }}
                >
                  <input
                    type="number"
                    id="B"
                    name="B"
                    maxLength="20"
                    value={valuetwo}
                    onChange={inputtwo}
                  />
                  <label htmlFor="B" className="withoutArrow">
                    <p>
                      {unitTwo.name}{" "}
                      {unitTwo.symbol ? `(${unitTwo.symbol})` : ""}
                    </p>
                  </label>
                </form>
              </ConverterSideB>
            </Column>
          </Columns>
        </ConverterWrapper>
        <br />
        <UnitsInfo
          unitOne={unitOne}
          unitTwo={unitTwo}
          pathOfUnitParent={pageContext.pathOfUnitParent}
          unitOf={pageContext.unitOf}
          infoNode={pageContext.infoNode}
        />
        <br />
        {pageContext.unitOf !== "temperature" && (
          <HowManyInAUnit
            unitOne={unitOne}
            unitTwo={unitTwo}
            selectOne={selectOne}
            selectTwo={selectTwo}
          />
        )}

        <br />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ListUnitsLink pathpages={pageContext.pathpages} />
          </React.Suspense>
        )}
      </div>
      <br />
    </Layout>
  )
}

export default IndexPage
